// cache worker config
export const CACHE_WORKER_VERSION = 'v1.2';

export const IS_HALLOWEEN =
    new Date() > new Date('2023-10-30') && new Date() < new Date('2023-11-06');

export const IS_NEW_YEAR =
    new Date() > new Date('2023-12-25') && new Date() < new Date('2024-01-08');

export const IS_BURN_FEST_AUCTION =
    new Date() >
    new Date(import.meta.env.VITE_BURN_FEST_AUCTION_START ?? '2024-02-29T06:00:00.000Z');

export const developFlags = {
    mockBattle: false,
    mockBattleRoyal: false,
};
