import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import Honeybadger from '@honeybadger-io/js';
import registerServiceWorker from './registerServiceWorker';
// import { registerTelegramAnalytics } from './registerTelegramAnalytics';
import { saveDataFromUrlToStorage } from './saveDataFromUrlToStorage';

const App = lazy(() => import('./app/App'));

// export const honeybadger = Honeybadger.configure({
//     apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
//     environment: import.meta.env.VITE_MODE ?? 'development',
//     revision: 'git SHA/project version',
// });

registerServiceWorker();
// registerTelegramAnalytics();
saveDataFromUrlToStorage();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Suspense
        fallback={
            <div className="lds-backdrop">
                <div className="lds-dual-ring"></div>
            </div>
        }
    >
        <App />
    </Suspense>
);
